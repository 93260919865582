import React from "react";
import { graphql, Link } from "gatsby";
import { Grid, Button, Icon } from "semantic-ui-react";
import Helmet from "react-helmet";
import { PageLayout } from "@fishrmn/fishrmn-components";
import Layout from "../components/Layout";
import FoodMenu from "../components/fisherman/FoodMenu";
import "./menu.css";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    const menu = {
      schedules: data.menu.schedules,
      categories: data.menu.categories,
      items: data.menu.items,
      modifierSets: data.menu.modifier_sets,
      modifiers: data.menu.modifiers,
    };

    /*
    const heroElement = <HeroElement
      header="Menu"
      tagline={ "KFC meal favorites starting at just $6.99 per person" }
      images={ [ data.heroImages.map( ({url}) => url)[1] ] }
      showMap={ false }
      ctas={ [] }
      ctaInverted={ true }
      ctaColor={ "white" }
      containerColor={ "primary" }
      textColor={ "white" }
      showMultiple={ true }
      overlay={ true }
      fullWidth={ true }
      parallax={ true }
      containerAs={ "none" }
      height={ "100vh" }
      gutter={ false }
    />
    */

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Menu</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout hero={null}>
          <Grid
            stackable
            className="component-section-container no-bottom-padding"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column
              width={16}
              style={{ marginTop: "-80px", marginBottom: "40px" }}
            >
              <Button
                size="huge"
                target="_blank"
                href="https://fisherman.gumlet.io/public/kfc2/74968_KFC_Catering_Menu-5.pdf"
              >
                <Icon name="file pdf outline" /> View PDF Menu
              </Button>
            </Grid.Column>

            <Grid.Column width={16} textAlign="left">
              <FoodMenu
                menu={menu}
                centerNavigation
                //header=""
                //disableModifiers
                //disableSchedules
                //categoryDisplayVariant="HorizontalCardRow"
                //itemDisplayVariant="HorizontalCard"
              />
            </Grid.Column>
          </Grid>

          <Grid
            className="component-section-container no-top-padding"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <Button size="huge" className="kfc">
                <Link to="/order-now/" style={{ color: "white" }}>
                  Order Now
                </Link>
              </Button>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

/*
export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          menu {
            categories {
              items
              name
              id
            }
            items {
              modifier_sets
              description
              id
              name
              variations {
                id
                menu_item
                name
                order
                price
              }
            }
            modifier_sets {
              id
              min_allowed
              modifiers
              name
            }
            modifiers {
              id
              name
              price
            }
            schedules {
              available_days
              categories
              id
              name
            }
          }
          ctas {
            phone {
              link 
              text 
              internal 
            }
          }
          businessName 
          about 
          reviews {
            name 
            content 
            link 
          }
          phone 
          address {
            street 
            city 
            state 
            zip 
          }
          hours {
            open 
            close 
            day 
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url 
          }
          heroImages {
            url 
          }
        }
      }
    }
  }
`;
*/

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          menu {
            categories {
              items
              name
              id
            }
            items {
              modifier_sets
              description
              id
              name
              variations {
                id
                menu_item
                name
                order
                price
              }
            }
            modifier_sets {
              id
              min_allowed
              modifiers
              name
            }
            modifiers {
              id
              name
              price
            }
            schedules {
              available_days
              categories
              id
              name
            }
          }
          businessName
          about
          reviews {
            name
            content
            link
          }
          ctas {
            phone {
              link
              text
              internal
            }
          }
          address {
            street
            city
            state
            zip
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
